<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="content">
            <v-row class="ma-0" align="center">
                <h2 class="dashboard">
                    {{ connectorAvatar ? `Настройки источника «${connectorAvatar.name}»` : 'Загрузка' }}
                </h2>
                <HintIcon v-if="hintId" :hintId="hintId"></HintIcon>
            </v-row>
            <component
                @cancel="cancel"
                @changeSettings="changeSettings"
                :is="activeComponent"
                v-if="activeComponent"
                :isChange="true"
                :connector="connector"
            ></component>

            <h2 class="dashboard" v-else>
                {{ connectorAvatar ? 'В настоящее время настройки в этом источнике не поддерживается' : '' }}
            </h2>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations } from 'vuex'
import Markers from '@/components/addConnector/Markers.vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import settingsList from '@/components/connectorSettings/list'
import HintIcon from '@/components/HintIcon.vue'
import { CONNECTOR_TYPES } from '@/vars/general.js'
import cfg from '@root/config.js'
export default {
    name: 'ConnectorSettings',
    components: {
        HintIcon,
        Markers,
        Breadcrumbs,
    },
    data: () => ({
        CONNECTOR_TYPES,
        changedSettingsImportance: null,
        additionalSettingsData: null,
        settings: null,
        yCSimpleAuth: cfg.yClientSimpleAuth,
    }),

    computed: {
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        hintId() {
            let hintId = null
            if (this.connectorType === CONNECTOR_TYPES.yClients) {
                //hintId = 'ycSettings'
            }
            return hintId
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'НАСТРОИТЬ ИСТОЧНИК',
                    disabled: true,
                    to: '',
                },
            ]
        },
        isChangeConnectorSettingsDialog: {
            get() {
                return this.$store.state.project.isChangeConnectorSettingsDialog
            },
            set(value) {
                this.$store.commit('project/updateIsChangeConnectorSettingsDialog', value)
            },
        },
        connector() {
            return this.$store.getters['connectorById'](this.$router.currentRoute.params.connectorId)
        },
        connectorType() {
            return this.connector ? this.connector.connectorType : null
        },
        connectorAvatar() {
            return this.connector
                ? this.$store.getters['connectorAvatarByType'](this.connector.connectorType)
                : null
        },
        settingsImportance() {
            return this.connector
                ? this.$store.getters['connectorSettingsImportanceByType'](this.connector.connectorType)
                : null
        },
        activeComponent() {
            return settingsList.find(el => el.connectorType === this.connector.connectorType)?.component
        },
    },
    methods: {
        async changeSettings({ prevSettings, settings, additionalData }) {

            this.$store.dispatch('project/updateConnectorSettings', {
                connectorId: this.connector.connectorId,
                settings: settings,
            })

            this.cancel()
        },
        cancel() {
            const projectPath = this.$router.currentRoute.path
                .split('/')
                .slice(0, 3)
                .join('/')
            this.$router.push(`${projectPath}#connectors`)
        },
    },
    created() {
        this.$on('changeSettings', this.changeSettings)
        this.$on('cancel', this.cancel)
        this.$on('goToProject', this.cancel)
    },
    mounted() {},
}
</script>
<style lang="sass" scoped>
.connectorSettings
    padding-top: 5%
    padding-left: 10%
    padding-right: 10%
.cancel-btn
    border-color: #0000001F
</style>
